<template>
  <v-card class="mb-2">
    <v-card-title>LookupValueInput</v-card-title>
    <v-card-text>
      <p>Lässte den Benutzer einen LookupValue auswählen.</p>
      <v-row>
        <v-col
          ><LookupValueInput
            label="Abteilung"
            :items="items"
            v-model="selectedItem"
        /></v-col>
        <v-col> </v-col>
      </v-row>

      <code
        >&lt;LookupValueInput label="Abteilung" :items="items"
        v-model="selectedItem" /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import LookupValueInput from 'common/components/LookupValueInput.vue';
export default {
  name: 'LookupValueInputDemo',
  components: { LookupValueInput },
  data() {
    return { items: [], selectedItem: null };
  },
  async created() {
    this.items = await this.apiList({ resource: 'common/division' });
  },
};
</script>
