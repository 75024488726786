<template>
  <v-card class="mb-2">
    <v-card-title>DatePicker</v-card-title>
    <v-card-text>
      <p>Stellt ein Json-Datum in normalen Format dar.</p>
      <v-row>
        <v-col
          ><DatePicker v-model="date" :color="color ? color.code : null"
        /></v-col>
        <v-col
          ><LookupValueInput label="Farbe" v-model="color" :items="colors"
        /></v-col>
      </v-row>

      <code
        >&lt;DatePicker v-model="date" color="{{ color ? color.code : null }}"
        /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import DatePicker from '@/components/DatePicker';
import LookupValueInput from 'common/components/LookupValueInput.vue';

export default {
  name: 'DatePickerDemo',
  components: { DatePicker, LookupValueInput },
  data() {
    return {
      date: '',
      color: {},
      colors: [
        { id: 1, code: 'primary', description: 'Gymer-Blau' },
        { id: 2, code: 'info', description: 'Unterricht' },
        { id: 3, code: 'success', description: 'Organisation' },
        { id: 4, code: 'error', description: 'Menschen' },
        { id: 5, code: 'warning', description: 'myGymer' },
      ],
    };
  },
};
</script>
