<template>
  <v-card class="mb-2">
    <v-card-title>PersonInput</v-card-title>
    <v-card-text>
      <p></p>
      <v-row>
        <v-col
          ><PersonInput
            :group="group"
            :label="label"
            :hint="hint"
            :hide-details="hidedetails"
            :dense="dense"
            :single-line="singleline"
            :clearable="clearable"
            :persistent-hint="persistenthint"
        /></v-col>
        <v-col
          ><LookupValueInput label="group" v-model="selected" :items="groups" />
          <v-text-field label="label" v-model="label"></v-text-field>
          <v-text-field label="hint" v-model="hint"></v-text-field>
          <v-checkbox
            v-model="clearable"
            :label="`clearable: ${clearable.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="persistenthint"
            :label="`persistent-hint: ${persistenthint.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="hidedetails"
            :label="`hide-details: ${hidedetails.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="singleline"
            :label="`single-line: ${singleline.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="dense"
            :label="`dense: ${dense.toString()}`"
          ></v-checkbox>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-autocomplete/"
              target="_blank"
              >weitere props von v-autocomplete</a
            >
          </p>
        </v-col>
      </v-row>
      <code>&lt;PersonInput group="{{ group }}" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
export default {
  name: "PersonInputDemo",
  components: { LookupValueInput, PersonInput },
  data() {
    return {
      hidedetails: false,
      dense: false,
      clearable: false,
      singleline: false,
      persistenthint: false,
      label: "Person",
      hint: "eine Person",
      groups: [
        { id: 1, code: "student", description: "Schüler*innen" },
        { id: 2, code: "teacher", description: "Lehrer*innen" },
        { id: 3, code: "employee", description: "Angestellte" },
        { id: 4, code: "person", description: "Personen" },
        { id: 5, code: "parent", description: "Eltern" },
        { id: 6, code: "intern", description: "Praktikant*innen" },
      ],
      selected: {},
    };
  },
  computed: {
    group() {
      return this.selected ? this.selected.code : null;
    },
  },
  created() {
    this.selected = this.groups[1];
  },
};
</script>
