<template>
  <v-card class="mb-2">
    <v-card-title>TimeInput</v-card-title>
    <v-card-text>
      <p>Stellt eine Json-Zeit in normalen Format dar.</p>
      <v-row>
        <v-col><TimeInput v-model="time" label="Zeit auswählen" /></v-col>
      </v-row>

      <code>&lt;TimeInput v-model="time" label="Zeit auswählen" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import TimeInput from "common/components/TimeInput.vue";

export default {
  name: "TimeInputDemo",
  components: { TimeInput },
  data() {
    return {
      time: "",
    };
  },
};
</script>
